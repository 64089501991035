.title{
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: xx-large;
    background-color: #0E4749;
    padding: 1rem;
    font-family: "Righteous", sans-serif;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
}
.title h4{
  text-transform: uppercase;
}
.products-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    background-color: black;
  }
  
  .product-card {
    background-color: #ffffff; /* Darker background for the card */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.315);
    width: 500px;
    text-align: center;
  }
  
  .product-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .product-info {
    margin-top: 10px;
  }
  
  .product-name {
    font-size: 20px;
    color: #770000;
    font-family: "Righteous", sans-serif;
  }
  
  .product-price {
    font-size: 18px;
    color: #352b2b;
    font-family: "Righteous", sans-serif;
  }
  .loading{
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 768px) {
    .product-card {
      width: 100%; /* Full width for smaller screens */
      padding: 10px; /* Adjust padding for smaller screens */
    }
  }
  .pictures {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
  }
  .pictures img {
    width: 64px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s ease-in-out;
  }
  
  .pictures img:hover {
    transform: scale(1.1);
  }
  
  .img-active {
    box-shadow: 0 0 10px #fc2133, 0 0 10px #fc2133, 0 0 10px #fc2133;
    border-radius: 5px;
  }
  .picture img {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
  }

  .rect {
    position: absolute;
    width: 200px;
    height: 150px;
    background-color: rgba(120, 120, 124, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.6);
    transform: translate(-50%, -50%);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
 
  .rect-active {
    opacity: 1;
  }

  .zoom {
    position: absolute; /* Position relative to the nearest positioned parent */
    top: 0;
    left: 100%; /* Align to the right of the picture */
    margin-left: 20px; /* Add spacing between the picture and the zoom window */
    background-size: 1260px 1818px; /* Adjust for 3x zoom */
    background-repeat: no-repeat;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.4);
    width: 600px; /* Size of the zoom window */
    height: 450px; /* Adjust to match your zoomed content */
    opacity: 0; /* Hidden by default */
    transition: opacity 0.3s ease-in-out;
    z-index: 100;
}

.zoom-container {
    position: relative; /* Required for child elements like .zoom to use absolute positioning */
    display: flex;
    flex-wrap: nowrap; /* Ensure children are in a row without wrapping */
    align-items: flex-start;
}

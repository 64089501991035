/* Footer styles */

.footer {
    background-color:#0E4749; /* Dark background */
    color: #ffffff; /* Light text color */
    padding: 20px 0;
    text-align: center;
    font-family: "Righteous", sans-serif;
    border-top: 2px solid #ffffff;
  }
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
  }
  
  .footer-section {
    margin: 10px;
    flex: 1;
    min-width: 200px;
  }
  
  .footer-title {
    font-size: 25px;
    margin-bottom: 10px;
    color: rgb(255, 22, 22);
  }
  
  .footer-section p, .footer-section ul, .footer-section li {
    margin: 5px 0;
    list-style: none;
    color: #ffffff;
    text-decoration: none;
    font-size: 20px;
  }
  .footer-section a {
    color: #ffffff;
    text-decoration: none;
  }
  
  .footer-section a:hover {
    text-decoration: underline;
  }
  
  .footer-social-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  .footer-social-icons .whatsapp:hover{
    color: green;
  }
  .footer-social-icons .instagram:hover{
    color:palevioletred;
  }
  
  .footer-social-icons a {
    
    font-size: 24px;
    color: #ffffff;
    text-decoration: none;
    font-weight: bolder;
  }
  
  .footer-bottom {
    border-top: 1px solid #333;
    padding-top: 10px;
  }
  
  .footer-bottom p {
    margin: 0;
    color: #ffffff;
    font-size: x-large;
    letter-spacing: 1px;
  }
  .footer-bottom h3{
    color: #ffffff;
    font-size:medium;
  }
  .footer-bottom a{
    font-weight: bolder;
    text-decoration: underline;
  }
  .footer-bottom a:hover{
    color: rgb(153, 0, 0);
  }
  .footer-images{
    display: flex;
    width: 280px;
    margin: auto;
  }
  @media (max-width: 767px) {
    .footer-images{
      width: 200px;
      margin: auto;
    }
    .footer-bottom p{
      font-size: medium;
    }
    .footer-bottom h3{
      font-size: small;
    }
    .footer-section h2{
      font-size: medium;
    }
    .footer-section p{
      font-size: small;
    }
    .footer-section li{
      font-size: small;
    }
  }
  
 
  
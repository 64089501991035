body {
    font-family: Arial, sans-serif;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
    margin-top: 10px;
  }
  
  .dropdown-text {
    cursor: pointer;
    font-size: x-large;
    font-family: "Righteous", sans-serif;
    
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    font-family: "Righteous", sans-serif;
    background-color: white;
    color: black;
    font-weight: 700;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 13px;
    z-index: 1;
    border-radius: 4px;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown-button {
    background: none;
    border: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    padding: 0;
    margin: 0;
  }
  .dropdown-button.selected {
    background-color: #8a8989;
    color: #000000;
    width: 100%;
  }
/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid;
  border-color: #000000 #00000000;
  animation: l1 1s infinite;
}
.load{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes l1 {to{transform: rotate(.5turn)}}
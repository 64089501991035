.search-results {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    color: black;
    font-family: "Righteous", sans-serif;
    max-height: 200px;
    overflow-y: auto;
    border-radius: 10px;
    z-index: 100;
  }
  
  .search-results li {
    padding: 10px;
    cursor: pointer;
  }
  
  .search-results li:hover {
    background-color: #bebcbc;
  }

  /* cart icon */
  .cart-icon {
    position: relative;
  }
  
  .cart-count {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 0px 5px;
    font-size: 10px;
  }
  .drawer{
    font-family: "Righteous", sans-serif;
    background: black;
    color: white;
  }
  .drawer-search{
    border-radius: 4px;
  }
  .drawer-search-button{
    background-color: white;
    color: black;
  }
  @media (max-width: 767px) {
    .search-results{
      margin-left: 30px;
    }
  }
  
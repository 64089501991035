
.total{
    margin: 0;
    font-family: sans-serif;
    background: #34495e; /* Black to dark gray gradient background */
    color: #E0E0E0; /* Light text color */
  }
  
  .box {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .box h1, .box h3{
    color: #ffffff;
    font-weight: 700;
  }
  .box h3{
    text-align:justify;
    margin-bottom: 20px;
  }
  .box p {
    color: #B0B0B0;
    line-height: 1.6;
    margin-bottom: 20px;
    font-weight: 600;
    text-align:justify;
  }
  .box h1{
    font-size: xx-large;
    margin-bottom: 20px;
    color: rgb(196, 196, 196);
    text-align:center ;
  }
  
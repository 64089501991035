.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal.show {
    display: flex;
  }
  
  .modal-content {
    position: relative;
    max-width:42%; /* Set image to 50% of the screen width */
    max-height: 100%; /* Limit height to 80% of the screen */
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3); /* Optional shadow for better visibility */
  }
  
  .modal-content img {
    width: 100%; /* Make the image fit the modal content */
    height: auto; /* Maintain the image aspect ratio */
    display: block;
    margin: 0 auto;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: #000;
    cursor: pointer;
    background: #fff;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 768px) {
    .modal-content {
        max-width: 90%; /* Adjust width to 85% for smaller screens */
        max-height: 100%; /* Limit height to 80% for smaller screens */
    }
}
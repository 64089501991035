/* body {
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
} */

.container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Righteous", sans-serif;
}

.container h2 {
    font-family: "Archivo Black", sans-serif;
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 1rem;
    color: black;
}

.container label {
  display: block;
  margin: 10px 0 5px;
  color: black;
}

input[type="text"],
select {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #000000;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  
}

input[type="text"]:focus,
select:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
.container input{
  background-color: white;
  color: black;
}

.submit-btn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #218838;
}
.error {
  color: red;
  font-size: 12px;
}

.error-input {
  border-color: red;
}

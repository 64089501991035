/* Cart.css */
.cart-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: "Righteous", sans-serif;
  }
  
  .cart-title {
    text-align: center;
  }
  
  .cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 10px 0;
  }
  
  .item-info {
    flex: 1;
  }
  
  .item-name {
    font-weight: bold;
  }
  
  .item-price {
    color: #666;
  }
  .item-size{
      background-color: rgb(0, 0, 0);
      color: white;
      padding: 1px;
      text-align: center;
      border-radius: 5px;
      font-size: x-small;
  }
  
  .item-quantity {
    display: flex;
    align-items: center;
  }
  
  .quantity-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 0.2rem;
  }
  
  .quantity-button:hover {
    background-color: #0056b3;
  }
  .buy-button {
    background-color: #007bff;
    color: #fff;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
    margin-top: 10px;
    border-radius: 0.2rem;
  }
  
  .buy-button:hover {
    background-color: #0056b3;
  }
  
  .quantity {
    margin: 0 10px;
  }
  .cart-item img {
     width: 50px;
     height: 50px;
     border-radius: 50%;
  }
  .remove-button {
    background-color: #dc3545;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 0.2rem;
  }
  .empty_label{
    text-align: center;
  }
  .remove-button:hover {
    background-color: #c82333;
  }
  
  .total-price{
    text-align: right;
    font-weight: bold;
    margin-top: 10px;
  }
  .coupon-btn{
    display: flex;
    gap: 1rem;
    
  }
  .coupon-btn :first-child{
    background-color: #007bff;
    color: white;
    padding: 5px 10px;
    border-radius: 0.2rem;

  }
  .coupon-btn :last-child{
    background-color: #c82333;
    color: white;
    padding: 5px 10px;
    border-radius: 0.2rem;
  }
  .discount-info {
    color: rgb(0, 175, 0);
    font-weight: bold;
    margin-top: 10px;
    text-align: right;
  }
  

  /* mobile size */
  @media (max-width: 767px){
    .cart-item img{
      border-radius: 0;
    }
  }
  
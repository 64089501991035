.account-box{
    background-color: white;  
}
.account-box h1{
    font-family: "Archivo Black", sans-serif;
    font-size: 40px;
    margin-top: 3rem;
    text-align: center;
}
.account-box h4{
    font-size: larger;
    margin-left: 1.5rem;
    text-transform: uppercase;
}
.account-box span{
    font-size: x-large;
    font-weight: bolder;
}
.account-box  h3{
    font-weight: 900;
    font-size: x-large;
    margin-left:1.5rem;
}
.order-box{
    border: 2px solid green;
    margin: 2rem;
    padding: 1rem;
    color: green;
}
.order-history{
    border: 2px solid green;
    margin: 2rem;
    padding: 1rem;
}
.order-item{
    display: flex;
    gap: 1rem;
}
.order-item span{
    font-weight: bolder;
}
.order-history h1{
    margin-bottom: 1rem;
    color: green;
}
.order-history img{
    width: 30px;
    height:30px;
    border-radius: 50%;
}
.order-box span{
    text-decoration: underline;
    font-size: large;
    font-weight: 700;
}

.user-table {
    width: 100%;
}
.table-box{
    padding: 2rem;
}
.table-box h1{
    font-family: "Archivo Black", sans-serif;
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 1rem;
}

.user-table th, .user-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.user-table th {
    background-color: #f2f2f2;
    color: black;
}
/* chat */
.order-history {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }
  
  .order-card {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .order-status {
    font-weight: bold;
    margin-bottom: 10px;
    color: green;
  }
  
  .order-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .item-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 20px;
  }
  
  .item-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .item-name,
  .item-price,
  .item-size,
  .item-quantity {
    font-size: 14px;
  }
  

@media (max-width: 767px) {
    .order-item{
        display: block;
        gap: 1.5rem;
    }
    .table-box{
        padding:0;
        overflow-x: auto;
        scrollbar-width: none;
        padding: 1rem;
    }
    .address-content{
        text-wrap: nowrap;
    }
   
  }
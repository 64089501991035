.forgot{
    background-color: blue;
    color: white;
    padding: 0.5rem;
    border-radius: 0.3rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}
.forgot-modal input{
    margin-top: 1rem;
    border: 3px solid black;
    border-radius: 0.3rem;
}


.single-product-container {
    max-width: 960px;
    margin: 2rem auto;
    padding: 1rem;
    font-family: "Righteous", sans-serif;
  }
  .single-product-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  .single-product-header h1{
    font-family: "Righteous", sans-serif;
  }

  
  .single-product-title {
    font-size: 2rem;
    font-weight: 600;
    font-family: "Bebas Neue", sans-serif;
    font-style: normal;
      
  }
  
  .single-product-price {
    color: #000000;
    font-size: 1.25rem;
    font-weight: 700;
  }
  
  .single-product-content {
    display: flex;
  }
  
  .single-product-image {
    width: 60%;
    padding-right: 1rem;
  }
   
  .single-product-description {
    font-size: 1.3rem;
    color: #ff0000;
    margin-bottom: 1rem;
    font-weight: bolder;
    margin-top: 10px;
  }
  
  .single-product-image img {
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .quantity-input-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .quantity-input-container label {
    margin-right: 0.5rem;
    font-weight: 700;
  }
  
  .quantity-input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.5rem;
    width: 60px;
    font-weight: 700;
  }
  
  .single-product-buttons {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  
  .add-to-cart-button,
  .buy-now-button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 500;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .out-of-stock{
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 500;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #ff0000;
    color: rgb(255, 255, 255);
    font-weight: 700;

  }
  
  .add-to-cart-button {
    background-color: #5e5e5e85;
    color: rgb(0, 0, 0);
    font-weight: 700;
  }
  
  .add-to-cart-button:hover {
    background-color: #000000;
    color: white;
    font-weight: 700;
  }
  
  .buy-now-button {
    background-color: #000000;
    color: white;
    font-weight: 700;
  }
  
  .buy-now-button:hover {
    background-color: #767777cc;
    color: #000000;
    font-weight: 700;
  }
  .scale{
    max-width: 25px;
    max-height: 25px;
    display: flex;
    margin-bottom: 2rem;
    cursor: pointer;

  }
  .scale p{
    white-space: nowrap;
    cursor: pointer;
    margin-top: 0.3rem;
    font-weight: 900;

  }
  .tshirt-size {
    margin-left: 10px;
    font-size: 13px;
    border: 2px solid #ffffff;
    border-radius: 5px;
    /* transition: all 0.3s ease; */
    cursor: pointer;
    background-color: #000000;
    color: #ffffff;
    outline: none;
    font-weight: 700;
    position: relative;
    height: 40px;
}
.tshirt-size-text{
  font-family:sans-serif;
  font-weight: 900;
}
.tshirt-size:focus {
    border-color: #ffffff;
    box-shadow: 0 0 10px rgba(255, 9, 9, 0.637);
    /* animation: pulse 0.5s infinite alternate; */
}
.old-price {
  text-decoration: line-through;
  color: red;
}
.discount-label {
  background-color: #ff0000; /* Bright red background */
  color: #000000; /* White text color */
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px; /* Rounded corners */
  margin-top: 10px; /* Space above the discount label */
  font-size: 0.9rem; /* Adjust font size as needed */
}
.description{
  font-size: 13px;
  font-family: "Righteous", sans-serif;
}
.description h1{
  font-weight: 900;
  font-size:medium;
}
.description p{
  font-size: small;
}

@keyframes pulse {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.05);
    }
}


@keyframes vibrate {
  0%, 100% {
    transform: translateX(0);
  }
  20%, 60% {
    transform: translateX(-2px);
  }
  40%, 80% {
    transform: translateX(2px);
  }
}

.vibrate {
  animation: vibrate 0.2s linear infinite;
}
/* Hide Zoom component by default (mobile and tablet sizes) */
.zoom-desktop-only {
  display: none;
}
.image-mobile-tab-only {
  display: block;
}

/* Show Zoom component for desktop sizes (min-width: 768px) */
@media (min-width: 768px) {
  .zoom-desktop-only {
    display: block;
  }
  .image-mobile-tab-only {
    display: none;
  }
  .single-product-details {
    margin-left: 8%;
    
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .single-product-image {
    width: 100%;
    height: 100%;
    padding-right: 0;
  }
  .single-product-price {
    font-size: 19px; /* Smaller font size for mobile screens */
  }

   .old-price {
    font-size: 19px; /* Smaller font size for mobile screens */
  }
  .single-product-title {
    font-size: 1rem;
    font-weight: bolder;
    font-family:  sans-serif;      
  }
  .single-product-details{
    width: 100%;
  }
}

.size-selection-container {
  margin: 20px;
}

.size-options {
  display: flex;
  gap: 10px;
}

.size-option {
  display: inline-block;
  position: relative;
}

.size-label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* Square size */
  height: 40px; /* Square size */
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.size-label.selected {
  background-color: #000000; /* Blue background for selected */
  color: white; /* White text for selected */
}

.hidden {
  display: none;
}

.header {
    display: flex;
    flex-direction: row; 
    justify-content:space-evenly;
    color: rgb(255, 255, 255);
    background-color:#000000;
}
.offer-container {
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    width: 100%;
    height: 30px; /* Ensure the container takes up the desired height */
    background-color: #eb2c2c;
    display: flex;
    align-items: center;
  }
  .poster_image{
    width: 100%;
  }
  .home_image{
    border-bottom: 2px solid #ffffff;
  }
  .poster{
    border-bottom: 2px solid #ffffff;
  }
  
  .offer {
    display: flex;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: rgb(255, 255, 255);
    font-size: 15px;
    letter-spacing: 1px;
    font-weight: bolder;
    padding-left: 100%; /* Start from off-screen */
    animation: marquee 27s linear infinite; /* Adjust the duration for speed */
    gap: 4rem;
  }
  .offer :last-child{
    color: black;
  }
  .offer :nth-child(2){
    color: black;
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  .main-back{
    background-color: black;
  }
  
.track{

    background-color: rgb(148, 148, 148);
    color: rgb(0, 0, 0);
    font-size: smaller;
    height: 25px;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
}
.header_logo{
    width: 150px;
    height:100px;
}
.header h5{
    margin-top: 2.5rem;
    font-weight: 900;
}
.header i {
    margin-top: 2.5rem;
    font-size: medium;
}
.home_image{
    width: 100%;
    
}
.icon_align{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    cursor: pointer;
}
.text_center{
    text-align: center;
    font-family: "Righteous", sans-serif;
    color: rgb(255, 255, 255);
    font-size: xx-large;
    background-color: #0E4749;
    padding: 1rem;
    border-bottom: 2px solid #ffffff;
}

.search-field {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
    margin-left: auto; 
    cursor: pointer;
  }
  .search-input {
    border:none;
    border-radius: 4px;
    width: 170px;
    background-color: rgb(255, 255, 255);
    font-family: "Righteous", sans-serif;
    color: black;
  }
  
  .search-input::placeholder {
    color: #999;
  }
.search-field:focus-within {
    border-color: #333; /* Changes border color when input or its descendants are focused */
  }
  .login_cart{
    margin-left: 20px;
  }
  .menu{
    display: none;
  }

  .goku-img, .text_center, .main-back {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .animate {
    opacity: 1;
    transform: translateY(0);
  }
  .animation{
    background-color:#ababab;
  }
  
  
  @media (max-width: 767px) {
    .login_cart , .search, .search_icon{
      display: none;
    }
    .menu{
      display: block;
    }
  }
  @media (max-width: 1024px) {
    .login_cart , .search, .search_icon{
      display: none;
    }
    .menu{
      display: block;
    }
  }
  
  
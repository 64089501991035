@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'flowbite';


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
.cl{
  font-size: large;
  color: red;   
}
/* Define the scrollbar width and height */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

/* The scrollbar track (background) */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* The scrollbar thumb (handle) */
::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #6a11cb, #2575fc); 
  border-radius: 10px;
}

/* The scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #6a11cb, #2575fc);
}
.user-box{
    font-family: "Righteous", sans-serif;
    display: flex;
}

.sidebar {
    width: 250px;
    min-height: 100vh;
    background-color: #2c3e50;
    color: #ecf0f1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.profile-section {
    text-align: center;
}
.profile-section h2{
    text-transform: uppercase;
}
.profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #ecf0f1;
}

.username {
    margin: 10px 0 20px 0;
}

.nav-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nav-item {
    width: 100%;
    padding: 15px 20px;
    text-align: left;
    text-decoration: none;
    color: #ecf0f1;
    font-size: 18px;
}

.nav-item:hover {
    background-color: #34495e;
}

.logout:hover {
    background-color: #c0392b;
}

.main-content {
    width: 100%;
}
@media (max-width: 767px) {
    .user-box{
        display: block;
    }
    .sidebar {
        width: 100%;
        min-height: 0px;
    }
    .nav-menu {
        text-align: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    
  }